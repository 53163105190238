<template>
    <div class="success_page">
        <!-- 成功标识 -->
        <div class="success_mark">
            <i class="el-icon-success success_icon"></i>
            <span>录入成功</span>
        </div>
        <!-- 后续操作 -->
        <div class="opreation_button">
            <van-button type="primary" @click="toComfirm">前往确认故障</van-button>
            <van-button type="default" @click="toEnterFault">再次填写</van-button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

import { Button } from 'vant';
Vue.use(Button)
export default {
    data() {
        return {}
    },
    methods: {
        toComfirm() {
            let id = this.$route.query.id
            this.$router.push({
                path: '/toconfirm',
                query: { id: id }
            })
        },
        toEnterFault() {
            this.$router.back()
        }
    }
}
</script>

<style lang="scss" scoped>
.success_page {
    background-color: #f7f8fa;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1.25rem;

    .success_mark {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.25rem;

        .success_icon {
            color: green;
            font-size: 150px;
        }
    }

    .opreation_button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .van-button {
            border-radius: 0;
            width: calc(100% - 2.5rem);
        }
    }
}
</style>